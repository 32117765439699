// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.achievements-container {
    text-align: justify;
    margin: 0 auto; /* center the container horizontally */
    padding: 20px;
    max-width: 1000px; /* adjust max-width as needed */
}

.achievements-heading {
    font-size: 2rem; /* adjust font size as needed */
    color: #f1675d;
}

.achievements-box {
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.achievement-list {
    list-style-type: none;
    padding: 0;
}

.achievement-item {
    font-size: 1.1rem; /* adjust font size as needed */
    margin-bottom: 10px;
}

/* Add bullet points */
.achievement-item::before {
    content: "•";
    margin-right: 8px;
}

@media (max-width: 768px) {
    .achievements-heading {
        text-align: left;
    }

    .achievements-container {
        text-align: left;
        margin: 0 auto; /* center the container horizontally */
        padding: 20px;
        max-width: 1000px; /* adjust max-width as needed */
    }


}`, "",{"version":3,"sources":["webpack://./src/styles/achievements.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc,EAAE,sCAAsC;IACtD,aAAa;IACb,iBAAiB,EAAE,+BAA+B;AACtD;;AAEA;IACI,eAAe,EAAE,+BAA+B;IAChD,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,iBAAiB,EAAE,+BAA+B;IAClD,mBAAmB;AACvB;;AAEA,sBAAsB;AACtB;IACI,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,gBAAgB;IACpB;;IAEA;QACI,gBAAgB;QAChB,cAAc,EAAE,sCAAsC;QACtD,aAAa;QACb,iBAAiB,EAAE,+BAA+B;IACtD;;;AAGJ","sourcesContent":[".achievements-container {\n    text-align: justify;\n    margin: 0 auto; /* center the container horizontally */\n    padding: 20px;\n    max-width: 1000px; /* adjust max-width as needed */\n}\n\n.achievements-heading {\n    font-size: 2rem; /* adjust font size as needed */\n    color: #f1675d;\n}\n\n.achievements-box {\n    border-radius: 8px;\n    padding: 20px;\n    margin-top: 20px;\n}\n\n.achievement-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.achievement-item {\n    font-size: 1.1rem; /* adjust font size as needed */\n    margin-bottom: 10px;\n}\n\n/* Add bullet points */\n.achievement-item::before {\n    content: \"•\";\n    margin-right: 8px;\n}\n\n@media (max-width: 768px) {\n    .achievements-heading {\n        text-align: left;\n    }\n\n    .achievements-container {\n        text-align: left;\n        margin: 0 auto; /* center the container horizontally */\n        padding: 20px;\n        max-width: 1000px; /* adjust max-width as needed */\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
