// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.education-container {
    margin: 0 auto;
    padding: 20px;
    max-width: 1000px;
  }
  
  .education-heading {
    text-align: left;
    color: #f1675d;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .education-item {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
  }
  

  .education-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .education-details {
    font-weight: bold;
    font-size: 18px;
  }
  
  .education-gpa,
  .education-dates {
    margin-left: 10px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/EducationComponent.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,mBAAmB;EACrB;;;EAGA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,eAAe;EACjB;;EAEA;;IAEE,iBAAiB;EACnB","sourcesContent":[".education-container {\n    margin: 0 auto;\n    padding: 20px;\n    max-width: 1000px;\n  }\n  \n  .education-heading {\n    text-align: left;\n    color: #f1675d;\n    font-size: 2rem;\n    margin-bottom: 20px;\n  }\n  \n  .education-item {\n    display: flex;\n    align-items: center;\n    margin-bottom: 40px;\n  }\n  \n\n  .education-title {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .education-details {\n    font-weight: bold;\n    font-size: 18px;\n  }\n  \n  .education-gpa,\n  .education-dates {\n    margin-left: 10px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
