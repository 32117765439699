// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-container {
    margin: 0 auto; /* center the container horizontally */
    padding: 20px;
    max-width: 1000px; /* adjust max-width as needed */
}

.skills-box {
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.skills {
    font-size: 2rem; /* adjust font size as needed */
    color: #f1675d;
    text-align: left; /* align the heading to the left */
    margin-bottom: 20px; /* add some space below the heading */
}

.skills-list {
    list-style-type: none;
    padding: 0;
}

.skills-list li {
    font-size: 1.1rem; /* adjust font size as needed */
    margin-bottom: 10px;
}

/* Add bullet points */
.skills-list li::before {
    content: "•";
    margin-right: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/skills.css"],"names":[],"mappings":"AAAA;IACI,cAAc,EAAE,sCAAsC;IACtD,aAAa;IACb,iBAAiB,EAAE,+BAA+B;AACtD;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe,EAAE,+BAA+B;IAChD,cAAc;IACd,gBAAgB,EAAE,kCAAkC;IACpD,mBAAmB,EAAE,qCAAqC;AAC9D;;AAEA;IACI,qBAAqB;IACrB,UAAU;AACd;;AAEA;IACI,iBAAiB,EAAE,+BAA+B;IAClD,mBAAmB;AACvB;;AAEA,sBAAsB;AACtB;IACI,YAAY;IACZ,iBAAiB;AACrB","sourcesContent":[".skills-container {\n    margin: 0 auto; /* center the container horizontally */\n    padding: 20px;\n    max-width: 1000px; /* adjust max-width as needed */\n}\n\n.skills-box {\n    border-radius: 8px;\n    padding: 20px;\n    margin-top: 20px;\n}\n\n.skills {\n    font-size: 2rem; /* adjust font size as needed */\n    color: #f1675d;\n    text-align: left; /* align the heading to the left */\n    margin-bottom: 20px; /* add some space below the heading */\n}\n\n.skills-list {\n    list-style-type: none;\n    padding: 0;\n}\n\n.skills-list li {\n    font-size: 1.1rem; /* adjust font size as needed */\n    margin-bottom: 10px;\n}\n\n/* Add bullet points */\n.skills-list li::before {\n    content: \"•\";\n    margin-right: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
