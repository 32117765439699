// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* In your ImageGallery.css file */
.image-gallery-container {
  padding: 20px;
  max-width: 1000px; /* Adjust max-width for responsiveness */
  margin: 0 auto; /* Center the gallery */
}

.image-gallery {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
}

.image-column {
  flex: 1 1; /* Each column takes equal width */
  margin-right: 10px; /* Adjust space between columns */
}

.image-card {
  text-align: center;
}

.image-card img {
  width: 100%; /* Fill the card container */
  height: auto;
  border-radius: 8px;
}

.image-text {
  margin-top: 10px;
  font-size: 14px;
}

.gallery-heading {
  text-align: left;
  color: #f1675d;
  font-size: 2.2rem;
  margin-bottom: 25px;
}

/* Media query for mobile devices */
@media only screen and (max-width: 600px) {
  .image-column {
    flex: 1 1 40%; /* Each column takes full width on mobile */
    margin-right: 0; /* No margin on mobile */
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/ImageGallery.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;EACE,aAAa;EACb,iBAAiB,EAAE,wCAAwC;EAC3D,cAAc,EAAE,uBAAuB;AACzC;;AAEA;EACE,aAAa;EACb,eAAe,EAAE,yCAAyC;AAC5D;;AAEA;EACE,SAAO,EAAE,kCAAkC;EAC3C,kBAAkB,EAAE,iCAAiC;AACvD;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW,EAAE,4BAA4B;EACzC,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA,mCAAmC;AACnC;EACE;IACE,aAAa,EAAE,2CAA2C;IAC1D,eAAe,EAAE,wBAAwB;EAC3C;AACF","sourcesContent":["/* In your ImageGallery.css file */\n.image-gallery-container {\n  padding: 20px;\n  max-width: 1000px; /* Adjust max-width for responsiveness */\n  margin: 0 auto; /* Center the gallery */\n}\n\n.image-gallery {\n  display: flex;\n  flex-wrap: wrap; /* Allow items to wrap to the next line */\n}\n\n.image-column {\n  flex: 1; /* Each column takes equal width */\n  margin-right: 10px; /* Adjust space between columns */\n}\n\n.image-card {\n  text-align: center;\n}\n\n.image-card img {\n  width: 100%; /* Fill the card container */\n  height: auto;\n  border-radius: 8px;\n}\n\n.image-text {\n  margin-top: 10px;\n  font-size: 14px;\n}\n\n.gallery-heading {\n  text-align: left;\n  color: #f1675d;\n  font-size: 2.2rem;\n  margin-bottom: 25px;\n}\n\n/* Media query for mobile devices */\n@media only screen and (max-width: 600px) {\n  .image-column {\n    flex: 1 1 40%; /* Each column takes full width on mobile */\n    margin-right: 0; /* No margin on mobile */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
