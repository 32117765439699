// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Your existing styles */
body {
  margin: 0;
  padding: 0;
  font-family: monospace;
  color: #333;
}

.footer {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10px;
  margin-top: 50px;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  background-color: #f1675d;
  background-size: cover;
  background-position: center;
}



/* New styles for the text */
.atishay-text {
  color: black;
  font-size: 24px;
}




.divider {
  width: 70%; /* Set the width to 60% */
  margin: 0 auto; /* Center the divider */
  border: none; /* Remove default border */
  border-top: 2px solid #ffffff; /* Set the border style */
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA,yBAAyB;AACzB;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,aAAa;EACb,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,iBAAiB;EACjB,gBAAgB;EAChB,kCAA0B;UAA1B,0BAA0B;EAC1B,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;AAC7B;;;;AAIA,4BAA4B;AAC5B;EACE,YAAY;EACZ,eAAe;AACjB;;;;;AAKA;EACE,UAAU,EAAE,yBAAyB;EACrC,cAAc,EAAE,uBAAuB;EACvC,YAAY,EAAE,0BAA0B;EACxC,6BAA6B,EAAE,yBAAyB;AAC1D","sourcesContent":["/* Your existing styles */\nbody {\n  margin: 0;\n  padding: 0;\n  font-family: monospace;\n  color: #333;\n}\n\n.footer {\n  width: 100%;\n  height: 120px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  padding-top: 10px;\n  margin-top: 50px;\n  backdrop-filter: blur(5px);\n  background-color: #f1675d;\n  background-size: cover;\n  background-position: center;\n}\n\n\n\n/* New styles for the text */\n.atishay-text {\n  color: black;\n  font-size: 24px;\n}\n\n\n\n\n.divider {\n  width: 70%; /* Set the width to 60% */\n  margin: 0 auto; /* Center the divider */\n  border: none; /* Remove default border */\n  border-top: 2px solid #ffffff; /* Set the border style */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
