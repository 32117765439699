// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.experience-container {
    text-align: justify;
    margin: 0 auto; /* center the container horizontally */
    padding: 20px;
    max-width: 1000px; /* adjust max-width as needed */

  }
  
  .experience-heading {
    text-align: left;
    font-size: 2rem;
    color: #f1675d;
    margin-bottom: 20px;
  }
  
  .experience-item {
    margin-bottom: 40px;
  }
  
  .experience-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .experience-details {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .experience-description {
    line-height: 1.6;
  }
  
  @media only screen and (max-width: 768px) {
    .experience-item {
      text-align: left; /* Align text left on mobile */
    }
  }`, "",{"version":3,"sources":["webpack://./src/styles/ExperienceComponent.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc,EAAE,sCAAsC;IACtD,aAAa;IACb,iBAAiB,EAAE,+BAA+B;;EAEpD;;EAEA;IACE,gBAAgB;IAChB,eAAe;IACf,cAAc;IACd,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;EAClB;;EAEA;IACE;MACE,gBAAgB,EAAE,8BAA8B;IAClD;EACF","sourcesContent":[".experience-container {\n    text-align: justify;\n    margin: 0 auto; /* center the container horizontally */\n    padding: 20px;\n    max-width: 1000px; /* adjust max-width as needed */\n\n  }\n  \n  .experience-heading {\n    text-align: left;\n    font-size: 2rem;\n    color: #f1675d;\n    margin-bottom: 20px;\n  }\n  \n  .experience-item {\n    margin-bottom: 40px;\n  }\n  \n  .experience-title {\n    font-size: 20px;\n    margin-bottom: 10px;\n  }\n  \n  .experience-details {\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .experience-description {\n    line-height: 1.6;\n  }\n  \n  @media only screen and (max-width: 768px) {\n    .experience-item {\n      text-align: left; /* Align text left on mobile */\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
