// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gallery-container {
  margin: 0 auto; /* center the container horizontally */
  padding: 20px;
  max-width: 1000px; /* adjust max-width as needed */
}

.gallery-title {
  font-size: 2rem; /* adjust font size as needed */
  color: #f1675d;
  text-align: left; /* align the heading to the left */
  margin-bottom: 20px; /* add some space below the heading */
}

.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

.gallery-img {
  width: 100%;
  height: 200px; /* Set the height to the desired size */
  object-fit: cover; /* This ensures the image covers the specified dimensions */
  border-radius: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Gallery.css"],"names":[],"mappings":"AAAA;EACE,cAAc,EAAE,sCAAsC;EACtD,aAAa;EACb,iBAAiB,EAAE,+BAA+B;AACpD;;AAEA;EACE,eAAe,EAAE,+BAA+B;EAChD,cAAc;EACd,gBAAgB,EAAE,kCAAkC;EACpD,mBAAmB,EAAE,qCAAqC;AAC5D;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa,EAAE,uCAAuC;EACtD,iBAAiB,EAAE,2DAA2D;EAC9E,kBAAkB;AACpB","sourcesContent":[".gallery-container {\n  margin: 0 auto; /* center the container horizontally */\n  padding: 20px;\n  max-width: 1000px; /* adjust max-width as needed */\n}\n\n.gallery-title {\n  font-size: 2rem; /* adjust font size as needed */\n  color: #f1675d;\n  text-align: left; /* align the heading to the left */\n  margin-bottom: 20px; /* add some space below the heading */\n}\n\n.gallery {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 10px;\n}\n\n.gallery-img {\n  width: 100%;\n  height: 200px; /* Set the height to the desired size */\n  object-fit: cover; /* This ensures the image covers the specified dimensions */\n  border-radius: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
