// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary-container {
    text-align: justify;
    margin: 0 auto; /* center the container horizontally */
    padding: 20px;
    max-width: 1000px; /* adjust max-width as needed for desktop */
}

.summary {
    font-size: 2rem; /* adjust font size as needed */
    color: #f1675d;
    /* additional styling for the summary heading */
}

.professional-summary {
    font-size: 1.1rem; /* adjust font size as needed */
    /* additional styling for the professional summary */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
    .summary-container {
        max-width: 90%; /* adjust container width for mobile */
    }

    .professional-summary {
        font-size: 1rem; /* adjust font size for mobile */
        text-align: left;
    }
}
`, "",{"version":3,"sources":["webpack://./src/styles/summary.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc,EAAE,sCAAsC;IACtD,aAAa;IACb,iBAAiB,EAAE,2CAA2C;AAClE;;AAEA;IACI,eAAe,EAAE,+BAA+B;IAChD,cAAc;IACd,+CAA+C;AACnD;;AAEA;IACI,iBAAiB,EAAE,+BAA+B;IAClD,oDAAoD;AACxD;;AAEA,mCAAmC;AACnC;IACI;QACI,cAAc,EAAE,sCAAsC;IAC1D;;IAEA;QACI,eAAe,EAAE,gCAAgC;QACjD,gBAAgB;IACpB;AACJ","sourcesContent":[".summary-container {\n    text-align: justify;\n    margin: 0 auto; /* center the container horizontally */\n    padding: 20px;\n    max-width: 1000px; /* adjust max-width as needed for desktop */\n}\n\n.summary {\n    font-size: 2rem; /* adjust font size as needed */\n    color: #f1675d;\n    /* additional styling for the summary heading */\n}\n\n.professional-summary {\n    font-size: 1.1rem; /* adjust font size as needed */\n    /* additional styling for the professional summary */\n}\n\n/* Media query for mobile devices */\n@media (max-width: 768px) {\n    .summary-container {\n        max-width: 90%; /* adjust container width for mobile */\n    }\n\n    .professional-summary {\n        font-size: 1rem; /* adjust font size for mobile */\n        text-align: left;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
